import { useSkillTreeStore } from "@/store/skilltree";
import combos from '@/data/combos.json';
import skills from '@/data/skills.json';
import baseSkillBlocks from "@/data/baseSkillBlocks.json";
import enemies from '@/data/enemies.json';
import { findById, deepClone } from "./base";
const baseSkills = JSON.parse(JSON.stringify(baseSkillBlocks));


export function calculatePlayerSkills(player) {
    const sstore = useSkillTreeStore();

    let energies = {};
    sstore.placedBlocks.flat().filter(x => x != null).forEach(block => {
        if (block.resource != null)
            energies[block.resource] = { max: 10, regen: 0, current: 0 };
    });

    player.etc = {}

    calculateSkills(player, energies);
}

export function calculateSkills(unit, energies) {
    unit.skills.sort((a, b) => a.type === 'Addition' ? -1 : b.type === 'Addition' ? 1 : 0);

    unit.skills.forEach(skill => {
        if (skill.type == "Skill") {
            if (skill.cd != null)
                skill.ccd = 0;
            return;
        }
        let target = skill.attribute.target;
        let attr = skill.attribute.id;

        if (target == "etc") {
            if (skill.type == "Addition") {
                if (unit.etc[attr] == null)
                    unit.etc[attr] = 0;
                unit.etc[attr] += skill.level[skill.cLevel - 1];
            } else if (skill.type === "Percentage") {
                if (unit.etc[attr] == null)
                    unit.etc[attr] = 0;

                let sourceTarget = skill.source.target;
                let sourceAttr = skill.source.id;
                let add = energies[sourceTarget][sourceAttr] * (skill.level[skill.cLevel - 1] / 100);

                unit.etc[attr] += add;
                unit.etc[attr] = parseFloat(unit.etc[attr].toFixed(2));
            }
        }
        if (target && energies[target]) {
            if (skill.type === "Addition") {
                energies[target][attr] += skill.level[skill.cLevel - 1];
            } else if (skill.type === "Percentage") {
                let sourceTarget = skill.source.target;
                let sourceAttr = skill.source.id;
                let add = energies[sourceTarget][sourceAttr] * (skill.level[skill.cLevel - 1] / 100);

                energies[target][attr] += add;
                energies[target][attr] = parseFloat(energies[target][attr].toFixed(2));
            }
        }
    });

    Object.keys(energies).forEach(energy => {
        energies[energy].current = energies[energy].max;
    });

    unit.energies = energies;

    unit.dmg = calculatDmg(unit);

}

function calculatDmg(unit) {
    let dmg = 1

    unit.skills.filter(skill => skill.type == "Skill" && skill.cd == null).forEach(skill => {
        let val = getSkillDmg(unit, skill)
        dmg += val;
    });

    return dmg;
}

function getSkillDmg(unit, f) {
    let source = f.source.target;
    let attr = f.source.id;
    let p = f.level[f.cLevel - 1]
    return unit.energies[source][attr] * p / 100;
}

export function initEnemy(enemy) {
    const originalEnemy = findById(enemies, enemy.id);
    if (!originalEnemy) return null;

    let clone = deepClone(originalEnemy);
    clone.etc = {}
    let energies = initializeEnergies(clone.skills);
    let skillLevels = initializeSkillLevels(clone);

    updateSkillLevelsAndEnemyLevel(clone, skillLevels);
    clone.skills = buildNewSkills(skillLevels);

    calculateSkills(clone, energies);
    return clone;
}

function initializeEnergies(skills) {
    let energies = {};
    skills.forEach(skill => {
        energies[skill.id] = { max: 10, regen: 0, current: 0 };
    });
    return energies;
}

function initializeSkillLevels(enemy) {
    let skillLevels = new Map();
    if (enemy.must) {
        enemy.must.forEach(skill => {
            skillLevels.set(skill.id, skill.amount);
        });
    }
    return skillLevels;
}

function updateSkillLevelsAndEnemyLevel(enemy, skillLevels) {
    enemy.level = 0;
    enemy.skills.forEach(skill => {
        const baseSkill = baseSkills.find(base => base.id === skill.id);
        if (!baseSkill) return;

        for (let i = 0; i < skill.amount; i++) {
            enemy.level++;
            const randSkillId = baseSkill.skills[Math.floor(Math.random() * baseSkill.skills.length)];
            const newLevel = Math.min((skillLevels.get(randSkillId) || 0) + 1, 5);
            skillLevels.set(randSkillId, newLevel);
        }
    });
}

function buildNewSkills(skillLevels) {
    let newSkills = [];
    skillLevels.forEach((level, id) => {
        let skill = skills.find(s => s.id === id);
        if (skill) {
            newSkills.push({ ...skill, cLevel: level });
        }
    });
    return newSkills;
}


export function checkMissingSkills() {
    const uniqueElements = new Set();
    combos.forEach(combo => {
        uniqueElements.add(combo.first);
        uniqueElements.add(combo.second);
    });

    const missingCombos = [];
    const uniqueElementsArray = Array.from(uniqueElements);
    for (let i = 0; i < uniqueElementsArray.length; i++) {
        for (let j = 0; j < uniqueElementsArray.length; j++) {
            if (i === j) continue;

            const first = uniqueElementsArray[i];
            const second = uniqueElementsArray[j];

            const comboExists = combos.some(combo =>
                combo.first === first && combo.second === second
            );

            if (!comboExists) {
                missingCombos.push({ first, second });
            }
        }
    }

    console.log(missingCombos);
}