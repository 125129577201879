<template>
  <v-app>
    <v-app-bar color="primary" prominent>
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Converter Game</v-toolbar-title>

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="left" temporary>
      <v-list flat>
        <NavigationItem :title="'Home'" :icon="'mdi-home'" :to="'/'" />
        <NavigationItem :title="'Skill Tree'" :icon="'mdi-chart-timeline'" :to="'/skilltree'" />
        <NavigationItem :title="'Battle'" :icon="'mdi-sword'" :to="'/battle'" />
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useSkillTreeStore } from "@/store/skilltree";
import { usePlayerStore } from "@/store/player";

export default {
  name: 'App',

  data: () => ({
    drawer: false
  }),

  mounted() {
    const sstore = useSkillTreeStore();
    sstore.initilaize()

    const pstore = usePlayerStore();
    pstore.initilaize()

    setInterval(() => {
      pstore.save()
      sstore.save()
    }, 10 * 1000)

    window.addEventListener('beforeunload', () => {
      if (!pstore.isReset)
        pstore.save()
      if (!sstore.isReset)
        sstore.save()
    })

  },

  components: {
    NavigationItem: defineAsyncComponent(() => import('@/components/NavigationItem.vue'))
  }
}
</script>
