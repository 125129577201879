import { usePlayerStore } from "@/store/player";

export function getExp(player, enemyLevel) {
    let levelMargin = 2;
    let levelDiff = enemyLevel - player.level + levelMargin;
    let exp = 0;

    if (levelDiff > 0) {
        exp = Math.floor(120 * Math.pow(1.2, levelDiff));
    } else {
        exp = 100;
    }
    player.exp += exp;

    usePlayerStore().log(`You gained ${exp} exp from the fight. You now have ${player.exp}/ ${player.expToNextLevel} exp.`);

    checkLevelUp(player);
}

function checkLevelUp(player) {
    while (player.exp >= player.expToNextLevel) {
        player.exp -= player.expToNextLevel;
        player.level++;

        player.expToNextLevel = getExpToNextLevel(player.level)
        usePlayerStore().log(`You leveled up to level ${player.level}!`);
    }
}

function getExpToNextLevel(level) {
    return Math.floor(100 * Math.pow(1.5, level - 1));
}