export default {
    player: {
        name: 'Player',
        skills: [],
        energies: [],
        level: 0,
        isPlayer: true,
        exp: 0,
        expToNextLevel: 66
    },
    logger: [],
    timer: null,
    currentEnemy: null,
}