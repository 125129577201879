import { useSkillTreeStore } from "@/store/skilltree";
import { usePlayerStore } from "@/store/player";

export function saveStore(store) {
    const serializedState = JSON.stringify(store.$state);
    localStorage.setItem(store.$id, serializedState);
}

export function loadStore(store) {
    const serializedState = localStorage.getItem(store.$id);
    if (serializedState) {
        store.$state = JSON.parse(serializedState);
        return true
    }
    return false
}

export function hardReset() {
    const sstore = useSkillTreeStore();
    const pstore = usePlayerStore();

    localStorage.removeItem(sstore.$id);
    localStorage.removeItem(pstore.$id);

    pstore.isReset = true
    sstore.isReset = true

    location.reload()
}