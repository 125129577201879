<template>
  <div>
    <h1>Player</h1>
    <LevelBar />
    <h4>Skills: </h4>
    <div v-for="skill in player.skills" :key="skill.id">{{ skill.name }}: {{ skill.cLevel }}</div>
    <h4>Energies: </h4>
    <div v-for="(item, counter) in player.energies" :key="item">
      <div>{{ counter }}: {{ item.current }}/{{ item.max }}({{ item.regen }})</div>
    </div>
    <v-btn @click="hardReset">HardReset (no Warning)</v-btn>

    {{ player.etc }}
  </div>
</template>

<script>
import { hardReset } from "@/store/functions/save";
import { usePlayerStore } from "@/store/player";
import { defineAsyncComponent } from 'vue'

export default {
  name: "HomeView",
  computed: {
    player() {
      const store = usePlayerStore();
      return store.player;
    },
  },
  methods: {
    hardReset() {
      hardReset();
    }
  },
  components: {
    LevelBar: defineAsyncComponent(() => import('@/components/LevelBar.vue')),
  },

}
</script>

<style lang="scss" scoped></style>