export default {
    getEnemyLevel() {
        return this.currentEnemy.skills.reduce((acc, skill) => acc + skill.amount, 0);
    },
    getSkillPoints() {
        const usedSkillPoints = this.player.skills.reduce((acc, skill) => acc + skill.cLevel, 0);
        return this.player.level + 8 - usedSkillPoints;
    },
    getLevel() {
        return this.player.level;
    },
    getSkillLevel: state => skillId => {
        const skill = state.player.skills.find(skill => skill.id === skillId)
        if (!skill) {
            return 0
        } else
            return skill.cLevel
    }
}