import combos from "@/data/combos";
import combinedSkill from "@/data/combinedSkill";
import baseSkillBlocks from "@/data/baseSkillBlocks.json";
import { saveStore, loadStore } from '../functions/save';
const baseSkills = JSON.parse(JSON.stringify(baseSkillBlocks));

export default {
    initilaize() {
        if (loadStore(this)) {
            return;
        }

        this.isReset = false;

        const healthBlock = baseSkills.find(block => block.id === "health");
        if (healthBlock) {
            this.removeAll(healthBlock.id);
            this.addBlockToGrid(0, 0, healthBlock);
        }
    },
    save() {
        saveStore(this)
    },
    addBlockToGrid(row, column, block) {
        const updatedBlocks = [...this.placedBlocks];

        block.x = row;
        block.y = column;

        updatedBlocks[column][row] = block;

        this.placedBlocks = updatedBlocks;
        this.checkCombos();
        return true;
    },
    allBlocksBack() {
        for (let i = 0; i < this.placedBlocks.length; i++) {
            for (let j = 0; j < this.placedBlocks[i].length; j++) {
                let block = this.placedBlocks[i][j];
                if (block) {
                    if (block.id != "health" && !block.combined) {
                        this.addBlockToBase(block);
                        this.placedBlocks[i][j] = null;
                    }

                }
            }
        }
    },
    addBlockToBase(block) {
        if (block.combined) {
            return;
        }

        if (block.id == "health") {
            this.allBlocksBack()
            this.addBlockToGrid(0, 0, block);
            return;
        }

        if (this.skillBlocks.find(x => x.id === block.id)) {
            return;
        }
        block.x = null;
        block.y = null;

        this.skillBlocks.push(block);
    },
    removeFromBase(block) {
        const index = this.skillBlocks.findIndex(x => x.id === block);
        if (index !== -1) {
            this.skillBlocks.splice(index, 1);
            return true;
        }
        return false;
    },
    removeFromGrid(blockId) {
        for (let row = 0; row < this.placedBlocks.length; row++) {
            const column = this.placedBlocks[row].findIndex(block => block && block.id === blockId);
            if (column !== -1) {
                this.placedBlocks[row][column] = null;
                this.checkCombos();
                return true;
            }
        }
        return false;
    },
    dragToBase(block) {
        if (block.id == "health") return;
        this.addBlockToBase(block);
        this.removeFromGrid(block.id)
    },
    dragToGrid(row, column, block) {
        const targetBlock = this.placedBlocks[column][row]
        const isMoving = this.placedBlocks.flat().some(x => x && x.id == block.id);


        if (targetBlock != null) {
            if (targetBlock.combined) return;

            if (isMoving) {
                this.addBlockToGrid(block.x, block.y, targetBlock);
            } else {
                if (targetBlock.id == "health") return;
                this.addBlockToBase(targetBlock);
            }
        } else {
            if (this.getSlots <= 0 && !isMoving) {
                return;
            }
        }

        this.removeAll(block.id);
        this.addBlockToGrid(row, column, block);
    },
    resetBlock(block) {
        if (block.id == "health") return;
        if (this.removeFromGrid(block.id)) {
            this.addBlockToBase(block);
        } else {
            this.dragToGrid(0, 0, block)
        }
    },
    removeAll(block) {
        if (!this.removeFromBase(block)) {
            this.removeFromGrid(block);
        }
    },
    resetCombos() {
        for (let i = 0; i < this.placedBlocks.length; i++) {
            for (let j = 0; j < this.placedBlocks[i].length; j++) {
                let block = this.placedBlocks[i][j];
                if (block && block.combined) {
                    this.placedBlocks[i][j] = null;
                }
            }
        }
    },
    isValidPosition(x, y) {
        return x >= 0 && x < this.placedBlocks.length && y >= 0 && y < this.placedBlocks[x].length;
    },
    checkCombos() {
        const adjacentPairs = [];

        this.resetCombos();

        for (let i = 0; i < this.placedBlocks.length; i++) {
            for (let j = 0; j < this.placedBlocks[i].length; j++) {
                const adjacentPositions = [
                    [i + 1, j], [i, j + 1],
                ];

                for (const [x, y] of adjacentPositions) {
                    if (this.isValidPosition(x, y) && this.placedBlocks[i][j] && this.placedBlocks[x][y]) {
                        adjacentPairs.push(
                            {
                                first: this.placedBlocks[i][j],
                                second: this.placedBlocks[x][y],
                                positions: [[i, j], [x, y]]
                            });
                    }
                }
            }
        }

        let blockChanged = false;
        for (const pair of adjacentPairs) {
            for (const combo of combos) {
                const firstMatch = pair.first.id === combo.first;
                const secondMatch = pair.second.id === combo.second;
                if (firstMatch && secondMatch) {
                    if (this.applyCombo({
                        combinationId: combo.id,
                        positions: pair.positions
                    })) {
                        blockChanged = true;
                    }
                    break;
                }
            }
        }
        if (blockChanged) {
            this.checkCombos();
        }
    },
    applyCombo(match) {
        const [firstPosition, secondPosition] = match.positions;
        const combined = combinedSkill.find(x => x.id === match.combinationId);
        combined.combined = true;

        const sameRow = firstPosition[0] === secondPosition[0];
        const nextIndex = sameRow ? secondPosition[1] + 1 : secondPosition[0] + 1;

        const nextPosition = sameRow ? [firstPosition[0], nextIndex] : [nextIndex, firstPosition[1]];


        if (sameRow ? nextIndex < this.placedBlocks[firstPosition[0]].length : nextIndex < this.placedBlocks.length) {
            const nextBlock = this.placedBlocks[nextPosition[0]][nextPosition[1]];

            if (nextBlock) {
                this.addBlockToBase(nextBlock);
            }

            this.placedBlocks[nextPosition[0]][nextPosition[1]] = combined;
            return !!nextBlock;
        }
    }
};