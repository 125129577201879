import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/skilltree',
    name: 'SkillTree',
    component: () => import(/* webpackChunkName: "skilltree" */ '../views/SkillTreeView.vue')
  },
  {
    path: '/skilltree/edit',
    name: 'SkillTreeEdit',
    component: () => import(/* webpackChunkName: "skilltreeedit" */ '../views/SkillTreeEditView.vue')
  },
  {
    path: '/battle',
    name: 'Battle',
    component: () => import(/* webpackChunkName: "battle" */ '../views/BattleView.vue')
  },
  {
    path: '/fight',
    name: 'Fight',
    component: () => import(/* webpackChunkName: "fight" */ '../views/FightView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
