import { usePlayerStore } from "../player";



export default {
    getPlacedBlocks() {
        return this.placedBlocks;
    },
    getSkillBlocks() {
        return this.skillBlocks;
    },
    getPlacedLength() {
        return this.placedBlocks.flat().filter(x => x !== null && !x.combined).length;
    },
    getSlots() {
        return this.maxSkills - this.getPlacedLength;
    },
    isValid() {
        return this.placedBlocks.flat().some(x => x && x.id == "health")
    },
    maxSkills() {
        let level = usePlayerStore().getLevel;
        return Math.floor(level / 10) + 2;
    }
}