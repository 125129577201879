import { defineStore } from 'pinia';
import initialState from './state';
import actions from './actions';
import getters from './getters';

export const usePlayerStore = defineStore('player', {
    state: () => ({ ...initialState }),
    actions,
    getters
});
