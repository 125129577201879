import { usePlayerStore } from "@/store/player";

export function buySkill(skill) {
    const pstore = usePlayerStore();
    if (pstore.getSkillPoints > 0) {
        const pSkill = pstore.player.skills.find(s => s.id === skill.id)

        if (pSkill) {
            if (pSkill.cLevel >= pSkill.level.length) {
                return
            }

            pSkill.cLevel += 1;
        } else {
            skill.cLevel = 1;
            pstore.player.skills.push(skill);
        }
    }
    pstore.calculatePlayerSkill();
}

export function removePoint(skillId) {
    const pstore = usePlayerStore();
    const pSkill = pstore.player.skills.find(s => s.id === skillId)

    if (pSkill) {
        if (pSkill.cLevel > 0) {
            pSkill.cLevel -= 1;
        }
        if (pSkill.cLevel === 0) {
            pstore.player.skills = pstore.player.skills.filter(s => s.id !== skillId);
        }
    }
    pstore.calculatePlayerSkill();
}