
import router from '@/router';
import { calculatePlayerSkills, initEnemy } from '../functions/helper';
import { updateFight } from '../functions/fight';
import { removePoint, buySkill } from '../functions/skill';
import { saveStore, loadStore } from '../functions/save';

export default {
    log(msg) {
        this.logger.push(msg);
    },
    initilaize() {
        loadStore(this)
        this.calculatePlayerSkill()
        this.isReset = false;

    },
    calculatePlayerSkill() {
        calculatePlayerSkills(this.player);
    },
    setEnemy(enemy) {
        this.currentEnemy = initEnemy(enemy);

        if (router.currentRoute.value.path != '/fight')
            router.push('/fight');

        if (this.timer == null)
            this.startFight();
    },
    exitFight() {
        //eslint-disable-next-line
        Object.entries(this.player.energies).forEach(([_, energy]) => {
            energy.current = energy.max;
        });

        //eslint-disable-next-line
        Object.entries(this.player.skills).forEach(([_, skill]) => {
            if (skill.cd != null) {
                skill.ccd = 0;
            }
        });

        clearInterval(this.timer);
        this.timer = null;
        this.currentEnemy = null;
        router.push('/battle');
    },
    startFight() {
        this.timer = setInterval(() => {
            this.updateFight();
        }, 500);
    },
    updateFight() {
        updateFight(this.player, this.currentEnemy);
    },
    removePoint(skillId) {
        removePoint(skillId)
    },
    buySkill(skill) {
        buySkill(skill)
    },
    save() {
        saveStore(this);
    }
}