import { usePlayerStore } from "@/store/player";
import { getExp } from "./exp";

/**
 * Applies regeneration to each energy type of the entity, ensuring current energy does not exceed maximum energy.
 * 
 * @param {Object} entity - The entity (player or enemy) to apply energy regeneration to.
 */
function applyRegen(entity) {
    //eslint-disable-next-line
    Object.entries(entity.energies).forEach(([_, energy]) => {
        if (energy.regen) {
            energy.current = Math.min(energy.current + energy.regen, energy.max);
        }
    });
}

/**
 * Checks the health of the player and the enemy to determine if either has died.
 * Updates the game state accordingly via the game's store.
 * 
 * @param {Object} player - The player character.
 * @param {Object} enemy - The enemy character.
 */
function checkDeath(player, enemy) {
    const store = usePlayerStore();

    if (player.energies.health.current <= 0) {
        store.exitFight();
        store.log("You died");
    } else if (enemy.energies.health.current <= 0) {
        store.log(`${enemy.name} died`);
        getExp(player, enemy.level);
        store.setEnemy(enemy);
    }
}

/**
 * Updates the fight round, applying damage from the player to the enemy and vice versa,
 * then applies energy regeneration for both, and checks if either has died.
 * 
 * @param {Object} player - The player character.
 * @param {Object} enemy - The enemy character.
 */
export function updateFight(player, enemy) {
    let playerDmg = checkNextSkill(player);
    playerDmg = reduceDamage(enemy, playerDmg);
    applyDamage(enemy.energies, playerDmg, ['armor', 'health']);

    let enemyDmg = checkNextSkill(enemy);
    enemyDmg = reduceDamage(player, enemyDmg);

    applyDamage(player.energies, enemyDmg, ['armor', 'health']);

    applyRegen(player);
    applyRegen(enemy);

    checkDeath(player, enemy);
}

/**
 * Applies damage to a character based on a prioritized list of energies, 
 * first subtracting from armor (if included) and then from other specified energy types in order.
 * The last energy in the list can go negative if there's still remaining damage.
 * @param {Object} energies - The energies object of the character receiving damage, containing various types like armor, health, mana, qi, etc.
 * @param {number} damage - The amount of damage inflicted.
 * @param {string[]} livingEnergies - An array of strings specifying the order in which energy types should be depleted ('armor', 'health', 'mana', 'qi', etc.).
 */
function applyDamage(energies, damage, livingEnergies) {
    let remainingDamage = damage;
    for (let i = 0; i < livingEnergies.length; i++) {
        let energyType = livingEnergies[i];

        if (remainingDamage <= 0) break;

        if (energies[energyType]) {
            if (i === livingEnergies.length - 1) {
                energies[energyType].current -= remainingDamage;
                break;
            } else {
                let energyToDeplete = Math.min(energies[energyType].current, remainingDamage);
                energies[energyType].current -= energyToDeplete;
                remainingDamage -= energyToDeplete;
            }
        }
    }
}



/**
 * Reduces incoming damage based on the target's damage reduction percentage.
 * 
 * @param {Object} target - The target entity with potential damage reduction.
 * @param {number} dmg - The original incoming damage before reduction.
 * @return {number} - The reduced damage after applying damage reduction.
 */
function reduceDamage(target, dmg) {
    const damageReductionPercent = target.etc && target.etc.damageReduction ? target.etc.damageReduction : 0;
    const reducedDamage = dmg * (1 - (damageReductionPercent / 100));

    return reducedDamage;
}


/**
 * Checks for the next available and affordable skill and calculates its damage.
 * If no skills are available or affordable, returns the unit's base damage.
 * 
 * @param {Object} unit - The unit whose skills are being checked.
 * @returns {Number} - The damage value of the next available and affordable skill, 
 *                     or unit's base damage if no skill is available or affordable.
 */
function checkNextSkill(unit) {
    const skills = unit.skills.filter(skill => skill.type === "Skill");
    updateCooldowns(skills);

    for (const skill of skills) {
        if (skill.ccd === 0 && canCastSpell(unit, skill)) {
            return calculateSkillDamage(unit, skill);
        }
    }

    return unit.dmg;
}

/**
 * Updates the cooldown status for each skill in a list of skills.
 * Decrements the current cooldown (ccd) if it is greater than zero.
 * 
 * @param {Array} skills - An array of skill objects to update cooldowns for.
 */
function updateCooldowns(skills) {
    skills.forEach(skill => {
        if (skill.cd == null) {
            return;
        }

        if (skill.ccd > 0) {
            skill.ccd--;
        }
    });
}

/**
 * Checks if the unit has enough resources to cast the spell.
 * 
 * @param {Object} unit - The unit attempting to cast the spell.
 * @param {Object} spell - The spell being considered for casting.
 * @returns {Boolean} - True if the spell can be cast, false otherwise.
 */
export function canCastSpell(unit, spell) {
    if (!unit || !spell || !spell.cost || !unit.energies) {
        console.error('Missing unit, spell data, or spell cost.');
        return false;
    }

    const costType = spell.cost.target;
    const costAmount = parseInt(spell.cost.amount, 10);

    if (isNaN(costAmount)) {
        console.error('Invalid spell cost amount.');
        return false;
    }

    if (!unit.energies[costType] || unit.energies[costType].current < costAmount) {
        return false;
    }

    return true;
}

/**
 * Deducts the spell cost from the unit's resources, effectively casting the spell.
 * Assumes that canCastSpell has already been called and returned true.
 * 
 * @param {Object} unit - The unit casting the spell.
 * @param {Object} spell - The spell being cast.
 * @returns {Boolean} - True if the resources were successfully deducted, false otherwise.
 */
export function castSpell(unit, spell) {
    const costType = spell.cost.target;
    const costAmount = parseInt(spell.cost.amount, 10);

    unit.energies[costType].current -= costAmount;
    return true;
}



/**
 * Calculates the damage of a given skill based on the unit's attributes.
 * 
 * @param {Object} unit - The unit using the skill.
 * @param {Object} skill - The skill whose damage is to be calculated.
 * @returns {Number} - The calculated damage value.
 */
function calculateSkillDamage(unit, skill) {
    const source = skill.source.target;
    const attr = skill.source.id
    const percentage = skill.level[skill.cLevel - 1];
    skill.ccd = skill.cd;

    let dmg = skill.base

    castSpell(unit, skill);

    if (!unit.energies[source] || unit.energies[source][attr] === undefined) {
        console.error(`Unit does not have the required energy source or attribute: ${source}.${attr}`);
        return 0;
    }

    return dmg + unit.energies[source][attr] * percentage / 100;
}
